<template
  ><div class=" m-0 p-0">
    <div class="img360 m-0 p-0  myimg360" id="image360list"></div>
    <!-- <p v-else>ไม่พบรูป 360° / not found image 360°</p> -->
  </div>
</template>

<script>
export default {
  name: "Img630",
  props: ["sku360"],
  data() {
    return {};
  },
  mounted() {
    this.set_360();
  },
  methods: {
    async set_360() {
      try {
        //   let sku360 = "/images360/60342195";
        let url = `${this.$cons.URL_360}${this.sku360}`;
         
        inject_orbitvu("image360list", "@/assets/orbitvu12", "", {
          ovus_folder: url,
          auto_rotate_dir: "left",
          content2: "yes",
          width: "auto",
          height: "346",
          teaser: "onerotationslow",
          force_html5: "yes",
        });
      } catch (error) {}
    },
  },
};
</script>

<style scoped>

</style>
